.ourlatestnews{
    background-image: url("../../../asserts/BsckgroundImage.png");
    background-size:100% 60%;
    background-repeat: no-repeat;
    height: 50%;
   
}

.ourlatestnewscontainer{
    min-height: 1200px;
}
.reademore{
    color:var(--greenn-500)
}

.subscribe{
    font-family: Poppins;
font-size: 35px;
font-weight: 500;
line-height: 62px;
letter-spacing: -0.002em;
text-align: center;
color: var(--greenn-500
);

}
.subscribe0{
    color: #0E8E60;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 9.5px; /* 52.778% */
}
.paragraph{
    font-family: "Poppins";
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.002em;
              text-align: left;
}
.paragraph0{
    color: #000;
text-align: justify;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.newsreadmorebtn{
    background-color: #0E8E60 !important;
    border: 0;
}
/* @media screen and (max-width:768px){
    .subscribe{
    font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 10px;
letter-spacing: 0em;
text-align: left;
}
.ourlatestnewscontainer{
    min-height: 1400px;
}
.ourlatestnews{
    background-image: url("../../asserts/BsckgroundImage.png");
    background-size:100% 45%;
    background-repeat: no-repeat;
    height: 50%;
   
}
.paragraph{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: justified;
    
}
} */
#input {
    background-color: #fff !important;
    padding-left: 3px;
    color: #ffffff;
    border: 0px;
}
.joinNowbtn{
    border-radius: 12px;
    background: #0E8E60;
    color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.043px;
border: 0;
}
.newsarticlehead{
    color: #787878;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.028px;
}
.newsarticledesc{
    color: #000;
font-family: Poppins !important;
font-size: 16px;
font-style: normal;
font-weight: 500 !important;
line-height: normal;
letter-spacing: -0.032px;
}
.newsarticlereadmorebtn{
    color: #0E8E60;
/* font-family: Montserrat; */
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.028px;
}
.newlatestnewshd0{
    font-family: "poppins";
    font-size: 20px;
    font-weight: 700;
}