#ctg{
   
padding: 4px 14px;
gap: 8px;
border-radius: 15px;
background: #0E8E60;
color: #FFF;
text-align: center;
font-size: 8px;
font-family: Inter;
font-weight: 600;
float:  left;
clear: left;
/* max-width:50%;
min-width:80px; */
margin-top: 60%;
margin-left: 12px;
}
#usr{
    width: 30% ;
height: 30px;
flex-shrink: 0;
border-radius: 15px;
background: #FFF;
}
#usr img{
    height: 100%;
    border-radius: 50%;
}
#nm{
    color: #1C1C1C;
font-size: 10px;
font-family: Inter;
font-weight: 500;
}
#ttl{
    color: #1C1C1C;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
#desp{
    color: #424242;
font-size: 10px;
font-family: Inter;
}
#vwbtn{
    display: inline-flex;
    height: 30px;
    padding: 9px 11px;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 21px;
background: #0E8E60;
color: #FFF;
font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
#time{
    color: rgba(66, 66, 66, 0.39);
font-size: 10px;
font-family: Inter;
}
.forbg{
    background: rgba(14, 142, 96, 0.08);

}