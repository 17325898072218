/* Selfcrd.css */
.self-crd-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.crd {
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.btnsuccess:hover{
  color: white !important;
  background-color: #0E8E60 !important;
}
.crd:hover {
  transform: translateY(-5px);
}

.user-image {
  width: 30px;
  height: 30px;
}

.crd-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.price {
  padding-top: 6px;
  color: #0e8e60;
}

/* ...existing styles... */

/* SelfcrdList.css */
.self-crd-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem;
}

.my-3{
  margin-top: 12px;
  margin-bottom:12px;
}
.mx-1{
  margin-left: 4px;
  margin-right: 4px;
}
.crd-column {
  width: calc(20% - 40px); /* Adjust width and margin as needed for 4 crds per row */
  margin: 0 10px; /* Adjust margin as needed */
  box-sizing: border-box;
}

.crd {
  width: 290px;
  height: 377px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 0.5px solid #000;
  background: #fff;
  margin: 1rem;
}



.user-image {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 50%;
}

.crd-title {
  color: #000;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

.price {
  color: #0E8E60;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.pagination-dot {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.pagination-dot.active {
  background-color: #0E8E60;
  animation: colorMove 0.3s ease-in-out;
}

/* Hover effect for Buy Course button */
#hovering:hover {
  background-color: #0E8E60;
  color: #fff;
}

/* Animation for active pagination dot */
@keyframes colorMove {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
.d-flex{
  display: flex;
}
.justify-content-center{
  justify-content: center;
}
.align-items-center{
  align-items: center;
}
