.dataviewname{
    color: #000;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.067px;
}
.dataviewname0{
    color: #000;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.024px;
}
.dataviewdesc{
    color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 26.1px */
text-align: start;
}
.dataviewdesc0{
    color: #000;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 145%; /* 11.6px */
text-align: start;
}
.dataviewmentor{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.038px;
}
.dataviewmentor0{
    color: #000;
font-family: Poppins;
font-size: 8px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.019px;
}
.dataviewrating{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
/* line-height: normal; */
letter-spacing: -0.053px;
text-align: start;
/* border: 1px solid red; */
/* width: 50%; */
}
.dataviewrating0{
    color: #000;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.053px;
text-align: start;
/* border: 1px solid red; */
/* width: 50%; */
}
.dataviewtime{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.038px;
text-align: start;
/* border: 1px solid red; */
width: 100%;
}
.dataviewtime0{
    color: #000;
font-family: Poppins;
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.019px;
text-align: start;
/* border: 1px solid red; */
width: 100%;
}
.dataviewPrice{
    color: #0E8E60;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.053px;
}
.dataviewPrice0{
    color: #0E8E60;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.053px;
}
.dataviewbuybtn{
    color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.043px;
border-radius: 6px;
background-color: #0E8E60;
border: 0;
}
.dataviewbuybtn0{
    color: #FFF;
font-family: Poppins;
font-size: 7px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.043px;
border-radius: 6px;
background-color: #0E8E60;
border: 0;
}
.dataviewbuybtn:hover{
    /* background-color: white !important;
    color: #0E8E60 !important;
    border: 1px solid #0E8E60 !important; */
}