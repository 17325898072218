.heading{
    color: #0E8E60;
    font-family: "Poppins";
}
.abouteeducationimportnt{
    order:2;
}
.abouteeducationimportntdetails{
    order: 1;
}


@media screen and (max-width:767px){
    .abouteeducationimportnt{
        order:1;
    }
    .abouteeducationimportntdetails{
        order: 2;
    }
    .aboutdetailscontainer{
        height: 500px;
        padding-bottom: 50px;
    }
}
.greenbaground {
    background-color: #0E8E60;
    background-size: cover;
    padding: 40px;
    border-radius: 50px;
    color: white !important;
}
.mdastersindatasceince {
    background-image: url("../../asserts/BsckgroundImage.png");
    background-size: 100% 20%;
    background-repeat: no-repeat;
}