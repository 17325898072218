.heading{
    color: #0E8E60;
    font-family: "Poppins";
}
.abouteeducationimportnt{
    order:2;
}
.abouteeducationimportntdetails{
    order: 1;
}


@media screen and (max-width:767px){
    .abouteeducationimportnt{
        order:1;
    }
    .abouteeducationimportntdetails{
        order: 2;
    }
    .aboutdetailscontainer{
        height: 500px;
        padding-bottom: 0px;
    }
}
.greenbaground {
    background-color: #0E8E60;
    background-size: cover;
    padding: 40px;
    border-radius: 50px;
    color: white !important;
}
.mdastersindatasceince {
    background-image: url("../../../asserts/BsckgroundImage.png");
    background-size: 100% 20%;
    background-repeat: no-repeat;
}
.master-heading0{
    font-weight: 700;
    /* border: 1px solid red; */
    font-family: "poppins";
    font-size: 19px;
}
.master-pragraph0{
    font-weight: 400;
    /* border: 1px solid red; */
    font-family: "poppins";
    font-size: 10px;
}