#hed{
    color: #0E8E60;
font-size: 33px;
font-family: Poppins;
font-weight: 700;
letter-spacing: -0.495px;
}
#hed_0{
    color: #0E8E60;
font-size: 24px;
font-family: Poppins;
font-weight: 700;
letter-spacing: -0.495px;
}
#side1tt{
    color: #1C1C1C;
font-size: 10px;
font-family: Inter;
font-weight: 500;
}
#side2tt{
    color: #424242;
font-size: 10px;
font-family: Inter;
font-weight: 500;
}
#side1btn{
    display: inline-flex;
padding: 3px 12px;
align-items: flex-start;
gap: 8px;
border-radius: 15px;
background: #1C1C1C;
color: #EFEFEF;
color: #EFEFEF;
text-align: center;
font-size: 8px;
font-family: Inter;
font-weight: 600;
}
#side1btn_0{
    display: inline-flex;
padding: 3px 20px;
align-items: flex-start;
gap: 8px;
border-radius: 15px;
background: #1C1C1C;
color: #EFEFEF;
color: #EFEFEF;
text-align: center;
font-size: 8px;
font-family: Inter;
font-weight: 600;
}
#foryou1div{
    border-radius: 12px;
border: 5px solid #0E8E60;
background: #FFF;
}
#foryou1div_0{
    border-radius: 12px;
border: 3px solid #0E8E60;
background: #FFF;
}

#hed2{
    color: #1C1C1C;
font-size: 13px;
font-family: Inter;
font-weight: 600;
}
#hed2_0{
    color: #1C1C1C;
font-size: 19px;
font-family: Inter;
font-weight: 700;
}
#side3tt{
    color: #1C1C1C;
font-size: 10px;
font-family: Inter;
font-weight: 500;
}
#hed3{
    color: #1C1C1C;
font-size: 11px;
font-family: Inter;
font-weight: 600;
}
#hed3_0{
    color: #1C1C1C;
font-size: 19px;
font-family: Inter;
font-weight: 700;
}
#side2btn{
    color: #EFEFEF;
text-align: center;
font-size: 8px;
font-family: Inter;
font-weight: 600;
border-radius: 15px;
background: #1C1C1C;
display: flex;
padding: 8px 12px;
align-items: flex-start;
gap: 8px;
}