.courseNames ul {
    gap: 90px;
    list-style: none;
    font-size: x-large;
    padding: 30px;
    cursor: pointer;
    
    display: inline-flex;
  }

  .courseNames li {
    position: relative;
    transition: color 0.1s;
  }

  .courseNames li:hover {
    color: #0E8E60;
  }

  .courseNames li:hover::before {
    content: '';
    position: absolute;
    width: calc(100% + 4px);
    height: 3px;
    bottom: -5px;
    left: -2px;
    background-color: #0E8E60;
  }

  /* MainCard.css */
.courseNames {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  max-width: 100%;
  padding: 0 10px;
}

.courseNames img {
  width: 100%;
  max-width: 12.3125rem;
  height: auto;
  margin: 10px;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.container img:hover {
  transform: translateY(-5px);
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .courseNames{
    margin: 10px auto;
  }
  
  .courseNames img {
    margin: 5px;
  }

  .courseNames ul{
    display: none !important;
   
  }
}
@media only screen and (max-width: 760px) {
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff !important;
    border: 0 !important;
    /* border: 1px solid red; */
    border-color: #fff !important;
    color: #0E8E60 !important;
    width: auto;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-color: transparent transparent transparent transparent;
     background: #ffffff; 
     color: #000; 
     font-weight: 500; 
     border-top-right-radius: 0px; 
     border-top-left-radius: 0px; 
     transition: box-shadow 0.2s; 
     margin: 0 0 0 0 !important; 
     /* color: red !important; */
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
   z-index: 1; 
   display: block; 
   position: absolute; 
   bottom: 0; 
   height: 2px; 
   /* background-color: blue !important;  */
   transition: 500ms; 
   width: 100%; 
   margin: 0 0 0 0 !important; 
   margin-left: 0px;
 
  /* border: 1px solid red; */
}
.p-tabview-nav {
  display: flex !important;
  justify-content: start !important;
  align-items: center;
  list-style-type: none;
  flex: 1 1 auto;
  /* border: 1px solid red !important; */
  padding-right: 0 !important;
}
  
}
.splide__pagination {
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
  /* border: 1px solid red; */
}