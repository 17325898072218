#main2tt{
    color: #1C1C1C;
font-size: 19px;
font-family: Inter;

}
#main1tt{
    color: #1C1C1C;
font-size: 33px;
font-family: Poppins;
font-weight: 700;
letter-spacing: -0.495px;
}
#main1tt_0{
    color: #1C1C1C;
font-size: 20px;
font-family: Poppins;
font-weight: 700;
letter-spacing: -0.495px;
}
#main3tt{
    color: #8E8E8E;
font-size: 15px;
font-family: Inter;
font-weight: 500;
letter-spacing: -0.225px;
width: 811px;
}
#main3tt_0{
    color: #8E8E8E;
font-size: 12px;
font-family: Inter;
font-weight: 500;
letter-spacing: -0.225px;
width: 811px;
}
#briefdesc{
    color: #1C1C1C;
text-align: justify;
font-size: 19px;
font-family: Poppins;
font-weight: 300;
letter-spacing: -0.285px;
/* border: 1px solid; */
overflow: hidden;
display: inline-block;
}

#briefdesc img{
    max-width: 80%;
    height: auto;
}
.ridblgimg img{
    width: 100%;
    height: 100%;
}
/* .go3481359132{
background-position: center !important;
width: 50px !important;
height: 50px !important;
}
.go3394934884{
    background-position: center !important;
    width: 50px !important;
    height: 50px !important;
} */
.cmmnttxtarea:focus{
    box-shadow: none !important;
}
.addcmntbotn{
    /* border-radius: 25px; */
background: #0E8E60;
border: 0;
color: #FFF;
font-family: Poppins;
/* font-size: 32px; */
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: -0.48px;
border-radius: 25px;
}
.addcmntbotn:focus{
    box-shadow: none !important;
}
.bglstnmbr{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 117.5%;
letter-spacing: -0.24px;
}