.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: 0 !important;
    border-width: 0 0 1px 0;
}
.coursePopular{
    color: #0E8E60;
font-family: Poppins;
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.coursePopular0{
    color: #0E8E60;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.coursecategories{
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.coursecategories0{
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.coursebtn1{
    border-radius: 5px;
border: 1px solid #0E8E60;
background: #FFF;
color: #000;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
width: 30%;
}
.coursebtn10{
    border-radius: 5px;
    border: 1px solid #0E8E60;
    background: #FFF;
    color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
width: 45%;

}
.coursebtn1:hover{
    background: #0E8E60 !important;
color: #fff !important;
border: 0 !important;
}
.categoriesSeeMorebtn{
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.categoriesSeeMorebtn:hover{
    text-decoration: underline !important;
    cursor: pointer !important;
}
.categoriesSeeMorebtn0{
    color: #0E8E60;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.categoriesSeeMorebtn0:hover{
    text-decoration: underline !important;
    cursor: pointer !important;
}
.tabhdr{
    color: #000;
text-align: center !important;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 500;
margin-left: 50px;
}
.tabhdr0{
    color: #000;
text-align: start !important;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 500;
/* margin-left: 50px; */

}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff !important;
    border: 0 !important;
    border-color: #fff !important;
    color: #0E8E60 !important;
    /* width: 150%; */
    padding-left: 0;
    padding-right: 0;
}
.p-tabview .p-tabview-panels {
    background: #ffffff;
    padding: 0;
    border: 0 none;
    color: #495057;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
.p-tabview-nav {
    display: flex;
    /* justify-content: space-between; */
gap: 10%;
    align-items: center;
    list-style-type: none;
    flex: 1 1 auto;
    /* border: 1px solid red !important; */
    /* padding-right: 95px; */
}
/* .p-tabview .p-tabview-nav li {
    margin-right: 0;
    width: 25%;
} */
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #0E8E60 !important;
    transition: 500ms ;
    /* width: 150%; */
    /* margin-left: 30px ; */
    /* width: 200%; */


}
/* @media only screen and (max-width: 750px) {
    .p-tabview .p-tabview-nav .p-tabview-ink-bar {
        z-index: 1;
        display: block;
        position: absolute;
        bottom: 0;
        height: 2px;
        background-color: #0E8E60 !important;
        transition: 500ms ;
        margin-left: 12px !important;
        width: normal !important;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        border-color: transparent transparent transparent transparent;
        background: #ffffff;
        color: #000;
        padding-left: 0;
        margin-left: -50px !important;
        font-weight: 500;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        transition: box-shadow 0.2s;
        margin: 0 0 -2px 0;
        width: 63% !important;
    }
} */
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  
    border-color: transparent transparent transparent transparent;
    background: #ffffff;
    color: #000;
    /* margin-left: 50px ; */
    font-weight: 500;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    transition: box-shadow 0.2s;
    /* margin: 0 0 -2px 50px; */
   
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}
.p-tabview .p-tabview-nav {
    background: #ffffff;
    border: 0;
    border-width: 0 ;
}
.tabcrslbox{
    height: 370px;
    /* height: 50vh; */

    border-radius: 20px;
border: 2px solid #d9d9d9;
background: #FFF;
}
/* .splide__pagination {
    bottom: 1em;
    left: 0;
    padding: 0 1em;
    position: absolute;
    right: 0;
    z-index: 1;
    top: 5em;
    border: 1px solid red;
} */
.sdbox{
    height: 420px !important;
    /* border: 1px solid red; */
}
.tabcrslname{
    color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.029px;
}
.tabcrslcoursename{
    color: #000;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.036px;
text-align: start;
}
.tabrtng{
    text-align: start;
    
}
.tabprice{
    color: #0E8E60;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.038px;
}
.tabbuybtn{
    border-radius: 6px;
border: 1px solid #00B05A;
background-color: #fff;
color: #0E8E60;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.029px;
}
.tabbuybtn:hover{
    background-color: #0E8E60 !important;
    color: #fff !important;
    border: 0 !important;
}
.nextbtn{
    background-color: #fff !important;
    padding: 0 !important;
    box-shadow: none !important;
    height: 35px !important;
    width: 25px !important;
}
.dataviewname{
    color: #000;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.067px;
}
.dataviewname0{
    color: #000;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.024px;
}
.dataviewdesc{
    color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 145%; /* 26.1px */
text-align: start;
}
.dataviewdesc0{
    color: #000;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 105%; /* 11.6px */
text-align: start;
/* border: 1px solid red; */
/* height: 26px; */
/* white-space: nowrap; */
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow-y: hidden;
text-overflow: ellipsis;
}

.dataviewmentor{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.038px;
}
.dataviewmentor0{
    color: #000;
font-family: Poppins;
font-size: 8px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.019px;
}
.dataviewrating{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
/* line-height: normal; */
letter-spacing: -0.053px;
text-align: start;
/* border: 1px solid red; */
/* width: 50%; */
}
.dataviewrating0{
    color: #000;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.053px;
text-align: start;
/* border: 1px solid red; */
/* width: 50%; */
}
.dataviewtime{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.038px;
text-align: start;
/* border: 1px solid red; */
width: 100%;
}
.dataviewtime0{
    color: #000;
font-family: Poppins;
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.019px;
text-align: start;
/* border: 1px solid red; */
width: 100%;
}
.dataviewPrice{
    color: #0E8E60;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.053px;
}
.dataviewPrice0{
    color: #0E8E60;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.053px;
}
.dataviewbuybtn{
    color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.043px;
border-radius: 6px;
background-color: #0E8E60;
border: 0;
}
.dataviewbuybtn0{
color: #FFF;
font-family: Poppins;
font-size: 8px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.019px;
border-radius: 3px;
background: #0E8E60;
width: 70px;
flex-shrink: 0;
border: 0;
}
.dataviewbuybtn:hover{
    background-color: #fff !important;
    color: #0E8E60 !important;
    border: 1px solid #0E8E60 !important;
}
.fordott{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}