
.acrhdroffline0{
  color: #0E8E60;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}





.Accordian {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }

  
  .accordion {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #0E8E60;
    background: var(--white-white-10, #FEFFFF);
    overflow: hidden;
    margin-bottom: 10px;
    
  }
  
  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    flex-shrink: 0;
    padding: 0 10px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s;
  }
  
  .accordion.open .accordion-header {
    background-color: #0E8E60;
    color: white;
  }
  
  .accordion-content {
    padding: 10px;
    background-color: white;
  }
  
  .arrow {
    font-size: 16px;
  }
  
  .up {
    transform: rotate(180deg);
  }
  
  .arrow-container {
    display: flex;
    align-items: center;
  }
  /* ... (existing styles) ... */

.accordion-header {
    /* ... (existing styles) ... */
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
  
  .accordion-content {
    /* ... (existing styles) ... */
    color: rgba(0, 0, 0, 0.70);
text-align: justify;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 187.5%
  }
  .qsans{
    text-align: start !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .qsans0{
    text-align: start !important;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}
.p-accordion .p-accordion-content {
  padding: 1.25rem;
  border: 0;
  background: #ffffff;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  /* border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px; */
}