/* SelfTop.css */
.self-top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    overflow-x: hidden;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
    max-width: 100%;
    
  }
  .d-flex{
    display: flex;
  }
  .justify-content- center{
    justify-content: center;
  }
  .align-items-center{
    align-items: center;
  }
  .my-3{
    margin: 12px 0px 12px 0px;
  }
  .self-top-content {
    width: 50%;
    margin-left: 10%;
  }
  
  .self-top-image {
    width: 50%;
  }
  
  .image {
    width: 70%;
  }
  
 /* SelfTop.css */
/* ... other styles ... */

/* Media query for small screens */
@media (max-width: 767px) {
    .d-flex.justify-content-center.my-3 {
      display: inline-block;
      text-align: center;
    }
  
    .d-flex.justify-content-center.my-3 > div {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  
  .slimgdiv img{
    width: 100%;
  }