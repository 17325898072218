.primary-color{
    /* background-image: linear-gradient(94.59deg, #0E8E60 2.39%, #00B05A 97.66%); */

}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    /* background: #EEF2FF; */
    background-color: #c2d4cd !important;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #0E8E60 !important;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #0E8E60 !important;
    font-weight: 500;
}
.p-tieredmenu.p-tieredmenu-overlay {
    background: #ffffff;
    border: 0 none;
    border-radius: 5px;
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.1);
}
.sidebarofmobile{
    background-color: var(--greenn-500);
}
.p-sidebar-close{
    color: white;
}
.navdiv{
    background-color: #0E8E60;
}
.navdiv2{
background-color: #FFF;
color: #0E8E60 !important;
}
.headers{
    color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.headers2{
    color: #0E8E60;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.signupbtn{
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 5px;
background: #FFF !important;
border: 0;
}
.signupbtn2{
    color: #FFF;
    background-color: #0E8E60 !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 5px;
    background: #FFF;
    border: 0;
}