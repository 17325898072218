#popup_menu_right{
    width: fit-content;
}

.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
    background: #0E8E60 ;
    color: #ffffff;
    border-color: #0E8E60 ;
}
.signupbtn{
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 5px;
background: #FFF !important;
border: 0;
}



@media only screen and (max-width: 760px) {

    .p-menu .p-menuitem-link .p-menuitem-text {
        color: black ;
        font-size: 14px ;
        font-weight: 400;
    }
    .p-menu .p-menuitem-link {
        padding: 0.45rem 1.25rem;
        color: black ;
        border-radius: 0;
        transition: box-shadow 0.2s;
        -webkit-user-select: none;
        user-select: none;
        font-family: "poppins";
    }
    .lftmnu{
        z-index: 2102;
        transform-origin: center top;
        /* top: 265px !important; */
        left: 50% !important;
    }
}


