.shop{
    color: #000;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 105%; /* 48.6px */
}
.shop0{
    color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 48.6px */
}
.crt{
    color: #0E8E60;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 105%;
}
.crt0{
    color: #0E8E60;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 135%;
}
.quantity{
    color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 32.4px */
}
.quantity0{
    color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 32.4px */
}
.line{
    opacity: 0.75;
background: rgba(217, 217, 217, 0.75);
height: 4px;
border: 0;
}
.nametutor{
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.namecourse{
    color: #000;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 43.2px */
}
.icn{
    color: #263238;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.024px;
}
.pricecourse{
    color: #0E8E60;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.058px;
}
.rmv{
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.totl{
    color: #000;
font-family: Poppins;
font-size:20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.086px;
}
.totl0{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.086px;
}

.checkbtn{
    color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius:30px;
background: #0E8E60;
border: 0;
display: inline-block;
text-align: center;

}
.checkbtn:hover{
    color: #0E8E60 !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 30px;
    background: #FFF !important;
    border: 3px solid #0E8E60 !important;
    display: inline-block;
    text-align: center;
}
.checkbtn0{
    color: #0E8E60;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 7px;
background: #FFF;
border: 1px solid #0E8E60;
width: 100%;
display: inline-block;
text-align: center;

}
.checkbtn0:hover{
    color: #0E8E60;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 7px;
background: #0E8E60;
border: 0;
width: 100%;
display: inline-block;
text-align: center;

}

.checkbtn:focus{
    box-shadow: none !important;
}
.nametutor0{
    color: #0E8E60;
font-family: Poppins;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.namecourse0{
    color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 145%; /* 17.4px */
}
.icn0{
    color: #263238;
font-family: Poppins;
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.019px;
}
.pricecourse0{
    color: #0E8E60;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.029px;
}
.rmv0{
    color: #0E8E60;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.smallcrtimgbox img{
    width: 100%;
}