.para-lines{
    font-family: Poppins;
font-size: 19px;
font-weight: 300;
line-height: 44px;
letter-spacing: 0em;
text-align: left;

}
.liststyletype{
    list-style-type: upper-alpha;
}
.listparalines{
    padding-left: 5px;
}
@media screen and (max-width:768px){
    .para-lines{
        font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    
    }
}