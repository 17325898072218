.tabcrslbox{
  height: 370px;
  /* height: 50vh; */

  border-radius: 20px;
border: 2px solid #d9d9d9;
background: #FFF;
}
/* .splide__pagination {
  bottom: 1em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
  top: 23em;
  border: 1px solid red;
} */

.crscrtd{
    color: rgba(0, 0, 0, 0.70);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 156%; /* 31.2px */
}
.crscrtd0{
  color: rgba(0, 0, 0, 0.70);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 156%; /* 18.72px */
}
.crscrt{
    color: #0E8E60;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 156%;
}
.crscrt0{
  color: #0E8E60;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 156%;
}
.crslvl{
    color: #000;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 67.5px */
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: "100%",
}
.crslvl:hover{
  overflow: visible !important;
}
.crslvl0{
  color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 160%; /* 32px */
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}
.crsname{
    color: #0E8E60;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 135%;
}
.crsname0{
  color: #0E8E60;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 160%;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}
.crsdes{
    color: #000;
text-align: justify;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 155%; /* 33px */
}
.crsdes0{
  color: rgba(0, 0, 0, 0.70);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 165%; /* 19.8px */
text-align: start;
}
.bnbtn{
  color: #FFF;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 3px;
background: #0E8E60;
border: 0;
}
.bnprce{
  color: #0E8E60;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.038px;
}
.bnprce0{
  color: #0E8E60;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.038px;
}
.crswrating{
    color: #0E8E60;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.067px;
}

.crsrtng{
    color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.026px;
margin-top: 8px;
}

.crssidedivaa{
    border-radius: 15px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.crssidediv0{
  background: #FFF;

}
.crssideprice{
    color: #0E8E60;
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.077px;
}
.firststrike{
    color: #BDB9B9;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.038px;
}
.crsforpri{
    text-align: start;

}
.percent{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.043px;
margin-left: 5px;

}
.off{
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.043px;
margin-left: 5px;
}
.timer{
    color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.043px;
margin-left: 5px;

}
.crsbuynowbtn{
    border-radius: 15px;
background: #0E8E60;
color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
border: 0;
display: flex;
  justify-content: center;
  align-items: center;
}
  .crsadtocrtbtn{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 15px;
border: 1px solid #0E8E60 !important;
background: #FFF;

  }
  .crsadtocrtbtnR{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0E8E60;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
border-radius: 15px;
border: 1px solid #0E8E60 !important;
background: #FFF;

  }
  .crsadtocrtbtnR:focus{
    box-shadow: none;
  }
  .crsadtocrtbtn0{
    border-radius: 3px;
border: 0.2px solid #0E8E60;
background: #FFF;
color: #0E8E60;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .crsbynwbtn0{
     border-radius: 3px;
border: 0;
background: #0E8E60;
color: #FFF;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .crsquote{
    color: #BDB9B9;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.038px;
text-align: center;
  }
  .individualheader{
    color: #000;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 54px */
  }
  .individualheader0{
    color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 30px */
  }
  .individualheader2{
    color: #0E8E60;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 150%;
  }
  .individualheader20{
    color: #0E8E60;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 150%;
  }
  .indiduallist1{
    color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
margin-bottom: 15px;
  }
  .indiduallist10{
    color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
  }
  .indiduallist2{
    color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
margin-bottom: 20px;
  }
  .indiduallist20{
    color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
  }
  .listgreentext{
    color: #0E8E60;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .listgreentext0{
    color: #0E8E60;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
  }
  /* @media only screen and (max-width: 750px) {
    .p-tabview-nav {
      display: flex;
      justify-content: space-around;
      align-items: center;
      list-style-type: none;
      flex: 1 1 auto;
      padding-right: 0px;
  }
    .p-tabview .p-tabview-nav .p-tabview-ink-bar {
        z-index: 1;
        display: block;
        position: absolute;
        bottom: 0;
        height: 2px;
        background-color: #0E8E60 !important;
        transition: 500ms ;
       
        padding-left: 0 !important;
        margin-left: 0 !important;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link  {
        border-color: transparent transparent transparent transparent;
        background: #ffffff;
        color: #000;
        padding-left: 0;
       
        font-weight: 500;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        transition: box-shadow 0.2s;
        margin-left: 5px !important;
        width: 100% !important;
        text-align: center !important;
    }
    .p-unselectable-text.p-tabview-selected.p-highlight {
      width: 100%;
      padding-left: 0;
      padding-right: 0 !important;
      margin-right: 0 !important;
    }
    .p-unselectable-text {
      width: 100% !important;
      padding-left: 0;
    }
    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: #ffffff !important;
      padding: 10px;
      border-color: #fff !important;
      color: #0E8E60 !important;
      margin-left: 5px !important;
      width: 100% !important;
  }
  #pr_id_6_header_0,#pr_id_6_header_1, #pr_id_6_header_2, #pr_id_6_header_3 {
    background: #ffffff !important;
    padding: 10px;
    border-color: #fff !important;
    color: #0E8E60 !important;
    margin-left: 5px !important;
    width: 100% !important;
}
} */
  .individualtabhdr{
    color: #000;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
height: auto;
  }
  .individualtabhdr0s{
    color: #0E8E60;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }
  .custom-expand-icon{
    border: 1px solid #0E8E60;
    background-color: #FFF;
    color: #0E8E60;
    border-radius: 50%;
    /* width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center; */
    padding: 4px;
    margin-top: -8px;
    position: absolute;
    right: 0.5em;
    top: 40%;
}
.acrhdr{
    color: #0E8E60;
font-size: 16px;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.acrhdr0{
  color: #0E8E60;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.acrbdyttl{
  color: #888888;
font-size: 14px;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.255px;
}
.acrbdybtn{
  color: #FFF;
font-size: 10.995px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px;
display: flex;
width: 96px;
padding: 3.141px 9.424px;
justify-content: center;
align-items: center;
gap: 7.853px;
border-radius: 3.141px;
border: 0.785px solid #0E8E60;
background: #0E8E60;
}
.p-accordion-tab {
    
  margin-top: 10px;
}


.accordion-container{
  /* border-radius: 22px;
background: #FFF;
box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15); */
/* height: 50px; */
/* flex-shrink: 0; */
padding-left: 5px;
padding-right: 5px;
padding-top: 0;
padding-bottom: 0;
}
/* .accordion-tab{
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  padding-left: 2px;
  padding-right: 2px;
} */
.accordion-tab{
  /* border: 1px solid red; */
  border-radius: 12px;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15);
  background: #FFF;
/* z-index: 5; */
}
/* .accordion-container .p-accordion-header {
  height: 50px; 
  overflow: hidden;
} */
/* .p-accordion .p-accordion-header .p-icon {
  margin-top: -8px;
  position: absolute;
  right: 0.5em;
  top: 50%;
} */
.individualrequirement{
  color: #0E8E60;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.individualrequirement0{
  color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.individualrequirementlist{
  color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
margin-bottom: 10px;
}
.individualrequirementlist0{
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
margin-bottom: 5px;
}
.individualrequirementdesc{
  color: #000;
text-align: justify;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 38.5px */
}
.individualrequirementdesc0{
  color: #000;
text-align: start;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 38.5px */
}
.rolesheading{
  color: #000;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.seemorbtn{
  color: rgba(14, 142, 96, 0.75);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 135%; /* 24.3px */
letter-spacing: 0.18px;
}
.seemorbtn:hover{
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
  
}
.individualcrslhdr{
  color: #000;
text-align: center;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 44.5px; /* 123.611% */
}
.individualcrslhdr0{
  color: #000;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 44.5px; /* 123.611% */
}
.individualcrslhdr2{
  color: #0E8E60;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 44.5px;
}
.individualcrslhdr20{
  color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 44.5px;
}
.individualrequirementreviews{
  color: #000;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.individualrequirementreviews0{
  color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.individualrequirementreviews2{
  color: #0E8E60;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.individualrequirementreviews20{
  color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.individualrating{
  color: #000;
font-family: Poppins;
font-size: 34px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.individualrating0{
  color: #000;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.individualratingdesc{
  color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.individualratingdescR{
  color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.individualratingdesc0{
  color: #000;
font-family: Poppins;
font-size: 7px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.p-slider .p-slider-handle {
  position: absolute;
  cursor: grab;
  touch-action: none;
  display: none;
  z-index: 1;
}
.p-slider.p-slider-horizontal {
  height: 0.176rem;
}
.p-slider .p-slider-range {
  background: #0E8E60;
}
.individualpercnt{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
}
.individualpercnt0{
  color: #000;
font-family: Poppins;
font-size: 6px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
}
.individualrequirementrewieshd{
  color: #0E8E60;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.individualrequirementrewieshd0{
  color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.individualreviewname{
  color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.individualdate{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-align: start;
}
.individualreviewtext{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
}
.rprtbtn{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
}
.insthed{
  color: #0E8E60;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.insnm{
  color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.077px;
}
.insrating{
  color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.048px;
}
.insdesc{
  color: #000;
text-align: justify;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 155%; /* 38.5px */
letter-spacing: 0.24px;
}


/*for video**/

/* Adding Google Font  */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.custom-video {
    position: relative;
    top: 0;
    /* width: 100%; */
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    /* background-color: #00808097; */
}


.custom-video__container {
    position: relative;
    top: 0;
    /* width: 32vw;
    height: auto; */
    /* margin: 1em; */
    font-family: 'Oswald', sans-serif;
  

}



.custom-video__video {
  border: 0px !important;
    /* border-radius: 0.2em; */
    cursor: pointer;

}


.custom-video__control {
    position: absolute;
    top: 40%;
    left: 42%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    width: 3em;
    height: 3em;
    white-space: nowrap;
    line-height: 0;
    /* border: 1px solid red; */
}

video::-webkit-media-controls {
    position: relative;
    z-index: 1;
}
            
        


@keyframes progress {
  from{
    transform: scaleX(0);
  }
  to{
    transform: scaleX(1);
  }
}
.progress{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 10px;
  background: #0E8E60;

  transform-origin: left;
  animation: progress auto linear;
  /* animation-timeline: scroll(); */
  animation: scroll() 2s ease infinite;
}
.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: #0E8E60;
}
.p-rating .p-rating-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem #0E8E60;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
  color: #0E8E60;
}

input, select, textarea{
  color: black;
  font-family: 'Poppins';
}
input::placeholder{
  color: grey;
  font-family: 'Poppins';
}
textarea::placeholder{
  color: grey;
  font-family: 'Poppins';
}
.slfindistatmdlnam{
  color: #0E8E60;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 48.6px */
}
