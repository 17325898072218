
.sidebarofmobile{
    background-color: var(--greenn-500);
}
.p-sidebar-close{
    color: white;
}
.navdiv{
    background-color: #0E8E60;
}
.navdiv2{
background-color: #FFF;
color: #0E8E60 !important;
}
.headers{
    color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.headers2{
    color: #0E8E60;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.signupbtn{
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 5px;
background: #FFF !important;
border: 0;
}
.signupbtn2{
    color: #FFF;
    background-color: #0E8E60 !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 5px;
    background: #FFF;
    border: 0;
}