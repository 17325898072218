.bgttl{
    color: #0E8E60;
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 50.5%;
letter-spacing: -0.54px;
}
.bgttl0{
    color: #0E8E60;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 50.5%;
letter-spacing: -0.54px;
}
.bgttl2{
    color: #FFF;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 75%;
letter-spacing: -0.24px;
}
.bgttl3{
    color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 125%;
/* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.bgttl4{
    color: #FFF;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 125%;
}
.bglst{
    color: #FFF;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 117.5%;
letter-spacing: -0.24px;
}
.indvlblgdabba img{
    width: 100%;
    height: 100%;
}
@media screen and (max-width:768px){
  
   
    .bgttl0{
        color: #0E8E60;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 50.5%;
    letter-spacing: -0.54px;
    }
    .bgttl2{
        color: #FFF;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 75%;
    letter-spacing: -0.24px;
    }
    .bgttl3{
        color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    /* white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; */
    }
    .bgttl4{
        color: #FFF;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    }
}